const testIds = {
    "sidebar": "867730",
    "map": "b28582",
    "zoomInBtn": "ad1cfd",
    "zoomOutBtn": "06b943",
    "addEdgeBtn": "d14a0c",
    "addNodeBtn": "f315a9",
    "editEdgeToggleBtn": "fdc230",
    "editNodeToggleBtn": "f8f403"
};

export default testIds;